.content {
  color: var(--app-text-color);
  text-align: left;
  margin-bottom: 184px;

  > .topContainer {
    max-width: 1200px;
    padding: 1rem;
    margin: 0 auto;
    margin-bottom: 64px;
    text-align: center;

    h2 {
      text-align: center;
      font-size: 36px;
      font-weight: bold;
      color: #292d32;
      // line-height: 50px;
      display: inline-block;
    }

    .more {
      // display: inline-block;
      float: right;
      margin-top: 10px;
      font-weight: 500;
      font-size: 20px;
      color: var(--app-primary-color);
      cursor: pointer;
    }
  }

  > .container {
    width: 100%;
    // height: 420px;
    overflow-x: auto;
    overflow-y: hidden;
    // white-space: nowrap;
    display: flex;
    justify-content: center;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch; /* iOS 优化 */

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    > .cardContainer {
      display: inline-block;
      min-width: 300px;
      max-width: 300px;
      // height: 100%;
      border-radius: 12px;
      margin-right: 40px;
      margin-bottom: 10px;
      background: #ffffff;
      cursor: pointer;
      box-shadow: 0px 3px 5px 0 #eee;

      &:last-child {
        margin-right: 0;
      }

      > .icon {
        display: inline-block;
        width: 100%;
        aspect-ratio: 175/100;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      > .detailContainer {
        padding: 18px 24px 32px;

        > .title {
          font-weight: 600;
          font-size: 20px;
          color: var(--app-text-color);
        }

        > .time {
          margin-top: 8px;
          font-weight: 400;
          font-size: 16px;
          color: var(--app-text-60-color);
        }

        > .desc {
          margin-top: 16px;

          font-weight: 400;
          font-size: 16px;
          color: var(--app-text-60-color);

          display: -webkit-box;
          overflow: hidden;
          word-break: break-word;
          white-space: normal;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          text-overflow: ellipsis;
          line-height: 1.2;
        }
      }
    }
  }

  > .bottomMore {
    // display: inline-flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 40px);
    margin: 20px 20px 0;
    background: var(--app-primary-color);
    color: var(--other-text-color);
    text-align: center;
    height: 44px;
    border-radius: 22px;
    display: none;
    cursor: pointer;
  }
}

@media screen and (min-width: 899px) {
  .content {
    margin-bottom: 184px;
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .content {
    margin-bottom: 154px;
  }
}

@media (max-width: 769px) and (min-width: 576px) {
  .content {
    margin-bottom: 119px;

    > .bottomMore {
      display: inline-flex;
    }
  }
}

@media screen and (max-width: 577px) {
  .content {
    margin-bottom: 50px;

    h2 {
      font-size: 32px;
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 768px) {
  .content {
    > .topContainer {
      margin-bottom: 30px;

      .more {
        display: none;
      }
    }

    > .bottomMore {
      display: inline-flex;
    }
  }
}

